/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRegistrationDto } from './user-registration-dto';
import { AddressDto } from './address-dto';
import { OfficeReviewDto } from './office-review-dto';


export interface DentalOfficeDto { 
    name: string;
    apptFeedbackTimeInMinutes?: number;
    specialityOffice?: boolean;
    fax?: string;
    phoneNumber?: string;
    dentalOfficeEmail?: string;
    utcOffsetSeconds?: number;
    address: AddressDto;
    adminUser?: UserRegistrationDto;
    adminUserEmail?: string;
    inviteId?: number;
    affiliateLinkCode?: string;
    officeReviewDtos?: Array<OfficeReviewDto>;
}

