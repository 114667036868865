/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImageUploadDto } from './image-upload-dto';
import { FlashCardDto } from './flash-card-dto';


export interface FlashCardSetDto { 
    folderCode: string;
    title: string;
    description: string;
    isPrivate?: boolean;
    price?: number;
    imageUploadDto?: ImageUploadDto;
    published?: boolean;
    subjects: Set<string>;
    flashCards: Array<FlashCardDto>;
    _private?: boolean;
}

