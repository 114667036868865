/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Country } from './country';
import { GpsCoordinate } from './gps-coordinate';
import { City } from './city';


export interface Address { 
    zipCode?: string;
    street?: string;
    houseNumber?: string;
    dateCreated?: string;
    id?: number;
    city?: City;
    gpsCoordinate?: GpsCoordinate;
    country?: Country;
}

