/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsersStatsPojo } from './users-stats-pojo';
import { EntityStatusStatsPojo } from './entity-status-stats-pojo';
import { PeriodChangeStatsPojo } from './period-change-stats-pojo';


export interface AdminDashboardStatisticsPojo { 
    appointmentsBooked?: PeriodChangeStatsPojo;
    appointmentsFulfilled?: PeriodChangeStatsPojo;
    appointmentsDefaulted?: PeriodChangeStatsPojo;
    dentalOfficeStats?: EntityStatusStatsPojo;
    dentalProfessionalStats?: EntityStatusStatsPojo;
    subscriptionStats?: { [key: string]: number; };
    userStats?: UsersStatsPojo;
    dentalOfficeCount?: number;
    dentalOfficeTotalActiveSubscriptions?: number;
    totalAmountOfRevenue?: number;
    totNumOfCompletedReferral?: number;
    totNumOfCancelledReferral?: number;
    totNumOfScheduledReferral?: number;
}

