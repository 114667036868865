/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionCycleDto } from './subscription-cycle-dto';
import { PackageItemDto } from './package-item-dto';


export interface SubscriptionPackageDto { 
    name: string;
    packageItems: Array<PackageItemDto>;
    description?: string;
    code?: string;
    subscriptionCycles: Array<SubscriptionCycleDto>;
}

