/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RedirectViewServletContextJspConfigDescriptorJspPropertyGroups } from './redirect-view-servlet-context-jsp-config-descriptor-jsp-property-groups';
import { RedirectViewServletContextJspConfigDescriptorTaglibs } from './redirect-view-servlet-context-jsp-config-descriptor-taglibs';


export interface RedirectViewServletContextJspConfigDescriptor { 
    taglibs?: Array<RedirectViewServletContextJspConfigDescriptorTaglibs>;
    jspPropertyGroups?: Array<RedirectViewServletContextJspConfigDescriptorJspPropertyGroups>;
}

