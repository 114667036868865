/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameAndCodeDto } from './name-and-code-dto';
import { AddressPojo } from './address-pojo';
import { StudentEducationPojo } from './student-education-pojo';


export interface StudentPojo { 
    id?: number;
    name?: string;
    email?: string;
    mobileNumber?: string;
    displayPictureId?: number;
    educationInfo?: StudentEducationPojo;
    specialization?: Array<NameAndCodeDto>;
    languages?: Array<NameAndCodeDto>;
    address?: AddressPojo;
}

