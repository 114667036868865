/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationChannelPojo } from './notification-channel-pojo';
import { DentalOffice } from './dental-office';
import { NotificationTimePojo } from './notification-time-pojo';
import { PortalUser } from './portal-user';


export interface NotificationSettingPojo { 
    id?: number;
    name?: string;
    channels?: Array<NotificationChannelPojo>;
    notificationTimes?: Array<NotificationTimePojo>;
    dentalOffice?: DentalOffice;
    portalUser?: PortalUser;
    defaultSetting?: boolean;
    currentSetting?: boolean;
}

