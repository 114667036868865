/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationContextClassLoader } from './application-context-class-loader';
import { Environment } from './environment';


export interface ApplicationContext { 
    startupDate?: number;
    applicationName?: string;
    autowireCapableBeanFactory?: object;
    displayName?: string;
    parent?: ApplicationContext;
    id?: string;
    environment?: Environment;
    beanDefinitionCount?: number;
    beanDefinitionNames?: Array<string>;
    parentBeanFactory?: object;
    classLoader?: ApplicationContextClassLoader;
}

