/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DentalOffice } from './dental-office';


export interface CustomSubscriptionRequest { 
    link?: string;
    officeName?: string;
    email?: string;
    updatedAt?: string;
    appointmentDate?: string;
    dateCreated?: string;
    note?: string;
    phoneNumber?: string;
    contacted?: boolean;
    extranet?: boolean;
    contactPersonName?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
    id?: number;
    dentalOffice?: DentalOffice;
}

