/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressPojo } from './address-pojo';
import { DentalProfessionalSpecialization } from './dental-professional-specialization';
import { DentalProfessionalPojo } from './dental-professional-pojo';
import { DentalOfficePojo } from './dental-office-pojo';


export interface DentalOfficeProfessionalPojo { 
    dentalOfficePojo?: DentalOfficePojo;
    dentalProfessionalPojo?: DentalProfessionalPojo;
    specialization?: Array<DentalProfessionalSpecialization>;
    addressPojo?: AddressPojo;
    streetAddress?: string;
}

