/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeSlotDefinition } from './time-slot-definition';


export interface TimeslotPojo { 
    timeslotDefinition?: TimeSlotDefinition;
    startDateTime?: string;
    endDateTime?: string;
    alreadyBooked?: boolean;
    bookingCount?: number;
    dentalProfessional?: number;
}

