/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationChannelDto } from './notification-channel-dto';
import { TimeSettingDto } from './time-setting-dto';


export interface NotificationSettingDto { 
    name?: string;
    channels: Array<NotificationChannelDto>;
    notificationTimes: Set<TimeSettingDto>;
    reminderTime?: string;
    defaultSetting?: boolean;
    message?: string;
    appointmentId?: number;
}

