/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PayerDto } from './payer-dto';
import { PaymentItemDto } from './payment-item-dto';


export interface PaymentTransactionRequestDto { 
    id?: number;
    transactionId?: string;
    dateCreated?: string;
    lastUpdated?: string;
    providerTransactionReference?: string;
    merchantTransactionReferenceId?: string;
    amountInKobo: number;
    notifyOnStatusChange?: boolean;
    notificationUrl?: string;
    paymentProvider?: string;
    paymentChannel?: string;
    serviceTypeId?: string;
    paymentTransactionStatus?: string;
    payer: PayerDto;
    items?: Array<PaymentItemDto>;
    validateTransaction?: boolean;
    transactionValidationUrl?: string;
    instantTransaction?: boolean;
    customerTransactionReference?: string;
    currencyCode?: string;
    description?: string;
    amountPaid?: number;
}

