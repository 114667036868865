/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomDentalServiceDto } from './custom-dental-service-dto';
import { DentalServiceDto } from './dental-service-dto';
import { InsuranceServiceDto } from './insurance-service-dto';


export interface DentalOfficeAttributeDto { 
    languages?: Array<string>;
    insuranceService?: Array<InsuranceServiceDto>;
    services?: Array<DentalServiceDto>;
    additionalServices?: Array<DentalServiceDto>;
    newDentalService?: Array<CustomDentalServiceDto>;
    newDentalAdditionService?: Array<CustomDentalServiceDto>;
}

